<template>
  <div class="training-chat__answers w-full h-full">
    <div
      v-if="!reseted"
      class="training-chat__header bg-woot-50 dark:bg-woot-900"
    >
      <p class="mb-2 text-truncate w-full">{{ question }}</p>
    </div>
    <div class="training-chat__wrapper flex items-start mt-4">
      <img
        :src="imgLoader('logo-svg', 'svg')"
        alt="answers logo"
        class="training-chat__logo bg-white dark:bg-gray-950 w-9 h-9 rounded p-1"
      />
      <div v-if="answers.length > 0" class="training-chat__choices flex-1">
        <p class="training-chat__text text-sm w-full mb-2">
          {{ $t('training.content.choose') }}
        </p>
        <ai-radio
          class="answers__item my-2"
          v-for="(answer, index) in answers"
          :key="answer.answerId || index"
          :value="answer"
          :id="answer.answerId.toString()"
          v-model="selectedAnswer"
        >
          <training-chat-answer
            :answer="answer"
            :selected="selectedAnswer"
            @copy="copy"
          />
        </ai-radio>
        <ai-btn
          v-if="selectedAnswer && selectedAnswer.text"
          prepend-icon="check-circle"
          color="accent"
          @click="createIntent"
          >{{ $t('training.content.actions.save') }}</ai-btn
        >
      </div>
      <p v-else class="answers__text">
        {{ $t('training.content.no-answers') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { useIntentStore } from '@/stores/intents'
  import { computed, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { useChatStore } from '@/stores/chat'
  import TrainingChatAnswer from '@/components/app/training/TrainingChatAnswer.vue'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import AiRadio from '@/components/ui/AiRadio.vue'
  import imgLoader from '@/utils/img-loader'

  const route = useRoute()
  const chatStore = useChatStore()
  const intentStore = useIntentStore()

  const emits = defineEmits(['copy'])

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const selectedAnswer = ref<any>(() => chatStore.getters.selectedAnswer())
  const reseted = computed(() => intentStore.getters.reseted())
  const question = computed(() => chatStore.getters.question())
  const answers = computed({
    get: () => chatStore.getters.answers(),
    set: (value) => {
      chatStore.mutations.addAnswer(value)
    },
  })
  //TODO uncomment
  // const answer = computed({
  //   get: () => chatStore.getters.selectedAnswer(),
  //   set: (value) => {
  //     chatStore.mutations.setSelectedAnswer(value)
  //   },
  // })

  const createIntent = async () => {
    if (selectedAnswer.value) {
      await intentStore.actions.createIntent(orgId.value, nestId.value, {
        question: question.value,
        answer: selectedAnswer.value.text,
      })
      await intentStore.actions.fetchIntents(orgId.value, nestId.value, false)
    }
  }

  const copy = (text: string) => {
    emits('copy', text)
  }
</script>

<style scoped lang="scss">
  .training-chat {
    &__header {
      padding: 24px 100px;
    }

    &__logo {
      width: 32px;
      height: 32px;
      margin: 0 34px;
    }

    &__choices {
      margin-right: 100px;
    }

    &__answer {
      @apply bg-violet-500 text-white;

      &.active {
        background-color: rgba(229, 232, 235, 1);
      }

      &:hover {
        background-color: rgba(229, 232, 235, 1);
      }
    }
  }
</style>
