<template>
  <div class="w-full flex-1 flex items-center justify-start flex-col">
    <training-chat-save v-if="intent" :intent="intent" />
    <training-chat-reset v-if="reseted" :intent="reseted" />
    <training-chat-answers v-if="question && !intent" @copy="copy" />
    <empty-state
      v-else-if="!question && !intent && !reseted"
      class="my-auto"
      img-name="NoMessages"
      :title="$t('training.content.start')"
    />
    <!--    <h2-->
    <!--      v-if="!question && !intent && !reseted"-->
    <!--      class="text-borderColor text-center align-self-center text-2xl font-light my-auto px-4 px-lg-16"-->
    <!--    >-->
    <!--      {{ $t('training.content.start') }}-->
    <!--    </h2>-->
  </div>
</template>

<script setup lang="ts">
  import TrainingChatSave from '@/components/app/training/TrainingChatSave.vue'
  import TrainingChatReset from '@/components/app/training/TrainingChatReset.vue'
  import TrainingChatAnswers from '@/components/app/training/TrainingChatAnswers.vue'
  import { useIntentStore } from '@/stores/intents'
  import { computed, onMounted } from 'vue'
  import { useChatStore } from '@/stores/chat'
  import EmptyState from '@/components/ui/EmptyState.vue'

  const intentStore = useIntentStore()
  const chatStore = useChatStore()

  const emits = defineEmits(['copy'])

  const intent = computed(() => intentStore.getters.intent())
  const reseted = computed(() => intentStore.getters.reseted())
  const question = computed(() => chatStore.getters.question())

  const copy = (text: any) => {
    emits('copy', text)
  }

  onMounted(() => {
    intentStore.mutations.setIntent(null)
    intentStore.mutations.setResetedIntent(null)
    intentStore.mutations.setSavedIntent(null)
    chatStore.mutations.setQuestion('')
  })
</script>

<style scoped lang="scss"></style>
