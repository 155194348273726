<template>
  <div
    class="editable-title flex items-center w-full gap-1 mb-1"
    @mouseenter="showEdit"
    @mouseleave="hideEdit"
  >
    <span v-element-size="onResize" class="editable-title__hidden-text">{{
      computedValue
    }}</span>
    <input
      v-model="computedValue"
      :disabled="!enabled"
      :style="{ width: titleWidth + 'px' }"
      :class="[enabled ? 'dark:border-gray-500' : 'border-transparent']"
      class="editable-title__field px-1 -ml-1 border focus:border-woot-600 rounded h-8 bg-transparent dark:text-white focus:bg-woot-50 dark:focus:bg-woot-800"
      type="text"
    />
    <ai-btn
      v-if="!enabled && editBtn"
      icon="pencil"
      variant="clear"
      color="secondary"
      size="sm"
      @click="toggleEdit"
    />
    <ai-btn
      v-if="enabled"
      size="sm"
      color="success"
      prepend-icon="check-circle"
      @click="save"
      >{{ $t('nest.card.save') }}</ai-btn
    >
    <ai-btn
      v-if="enabled"
      icon="close"
      size="sm"
      variant="clear"
      color="secondary"
      @click="closeEdit"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import { vElementSize } from '@vueuse/components'
  import AiBtn from '@/components/ui/AiBtn.vue'

  const props = defineProps({
    modelValue: {
      type: String,
      required: true,
    },
  })

  const emits = defineEmits([
    'update:resource-title',
    'update:modelValue',
    'cancel',
  ])

  const titleWidth = ref(0)
  const enabled = ref(false)
  const editBtn = ref(false)

  const computedValue = computed({
    get: () => props.modelValue,
    set: (value) => {
      emits('update:modelValue', value)
    },
  })

  const toggleEdit = () => {
    enabled.value = !enabled.value
  }

  const closeEdit = () => {
    enabled.value = false
    emits('cancel')
  }

  const showEdit = () => {
    editBtn.value = true
  }

  const hideEdit = () => {
    editBtn.value = false
  }

  const save = async () => {
    emits('update:resource-title')
    toggleEdit()
  }

  const onResize = ({ width }: { width: number }) => {
    titleWidth.value = width + 10 // add 10px to offset padding
  }
</script>

<style scoped lang="scss">
  .editable-title {
    &__field {
      @apply min-w-3 focus:outline outline-woot-100 dark:outline-woot-500 transition-colors duration-200;
    }

    &__hidden-text {
      @apply -top-4 -left-4 max-h-0 opacity-0 absolute pointer-events-none;
    }
  }
</style>
