<template>
  <div class="flex items-center w-full mb-4 gap-2">
    <ai-icon icon="filter" />
    <ai-input
      v-model="filter.name"
      :placeholder="$t('history.content.filter.starter')"
      class="flex-1"
    />
    <ai-input
      v-model="filter.identifier"
      :placeholder="$t('history.content.filter.identifier')"
      class="flex-1"
    />
    <date-picker-range v-model="filter.date" :dark="isDark" />
    <ai-btn variant="smooth" @click="clear" color="secondary">{{
      $t('history.content.filter.clear')
    }}</ai-btn>
  </div>
</template>

<script setup lang="ts">
  import { computed, reactive, watch } from 'vue'
  import { useRoute } from 'vue-router'
  import moment from 'moment'
  import _ from 'lodash'
  import { useDialogsStore } from '@/stores/dialogs'
  import DatePickerRange from '@/components/app/global/DatePickerRange.vue'
  import AiIcon from '@/components/app/global/AiIcon.vue'
  import AiInput from '@/components/ui/AiInput.vue'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import { useDark } from '@vueuse/core'

  const isDark = useDark()

  const route = useRoute()
  const dialogStore = useDialogsStore()

  const props = defineProps({
    page: {
      type: Number,
      default: 1,
    },
    limit: {
      type: Number,
      default: 10,
    },
  })

  const emits = defineEmits(['update'])

  const formatDate = (date: string, type: string) => {
    if (!date) {
      return ''
    }
    if (type === 'from') {
      return moment(date).format('YYYY-MM-DD') + ' 00:00'
    }
    return moment(date).format('YYYY-MM-DD') + ' 23:59'
  }

  const filter = reactive({
    name: '',
    identifier: '',
    date: [],
  })

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  watch(
    filter,
    _.debounce(() => {
      dialogStore.actions.fetchHistoryDialogs({
        orgId: orgId.value,
        nestId: nestId.value,
        page: props.page,
        limit: props.limit,
        name: filter.name,
        identifier: filter.identifier,
        dateFrom: formatDate(filter.date[0], 'from'),
        dateTo: formatDate(filter.date[1], 'to'),
      })
      emits('update', filter)
    }, 500),
  )

  const clear = () => {
    filter.name = ''
    filter.identifier = ''
    filter.date = []
  }
</script>

<style scoped lang="scss">
  .text-spacer {
    max-height: 48px;
    border-top: solid 1px rgb(98, 77, 125);
    border-bottom: solid 1px rgb(98, 77, 125);
  }
</style>
