export default (state: any) => {
  const nestDialog = () => {
    return state.nestDialog
  }

  const deleteNestDialog = () => {
    return state.deleteNestDialog
  }

  const resourceDialog = () => {
    return state.resourceDialog
  }

  const resourceEditorDialog = () => {
    return state.resourceEditorDialog
  }

  const improveHistoryAnswerDialog = () => {
    return state.improveHistoryAnswerDialog
  }

  const loader = () => {
    return state.loader
  }

  return {
    nestDialog,
    deleteNestDialog,
    resourceDialog,
    resourceEditorDialog,
    improveHistoryAnswerDialog,
    loader,
  }
}
