<template>
  <div class="flex items-center w-full justify-end mt-4 gap-1">
    <div class="per-page flex items-center mr-4">
      <p class="text-sm mr-2 whitespace-nowrap text-gray-400">
        {{ $t('history.content.footer.per-page') }}
      </p>

      <select
        v-model="perPage"
        class="form-control text-sm ps-2 flex-grow-0 w-auto"
      >
        <option
          v-for="itemsLimit in [10, 20, 30, 40, 50]"
          :key="itemsLimit"
          :value="itemsLimit"
        >
          {{ itemsLimit }}
        </option>
      </select>
    </div>
    <ai-btn
      rounded
      variant="clear"
      color="secondary"
      icon="chevron-first"
      @click="goFirst"
      :disabled="paginationPage === 1"
    />
    <ai-btn
      rounded
      variant="clear"
      color="secondary"
      icon="chevron-left"
      @click="goPrevious"
      :disabled="paginationPage === 1"
    />
    <ai-btn
      rounded
      variant="clear"
      color="secondary"
      icon="chevron-right"
      @click="goNext"
      :disabled="paginationPage >= totalPages"
    />
    <ai-btn
      rounded
      variant="clear"
      color="secondary"
      icon="chevron-last"
      @click="goLast"
      :disabled="paginationPage >= totalPages"
    />
  </div>
</template>

<script setup lang="ts">
  import { computed, watch } from 'vue'
  import { useDialogsStore } from '@/stores/dialogs'
  import AiBtn from '@/components/ui/AiBtn.vue'

  const dialogStore = useDialogsStore()

  const props = defineProps({
    paginationPage: {
      type: Number,
      default: 1,
    },
    paginationLimit: {
      type: Number,
      default: 10,
    },
  })

  const emits = defineEmits(['update:paginationPage', 'update:paginationLimit'])

  const perPage = computed({
    get() {
      return props.paginationLimit
    },
    set(value) {
      emits('update:paginationLimit', value)
    },
  })

  const count = computed(() => dialogStore.getters.dialogsCount())

  const totalPages = computed(() =>
    Math.ceil(count.value / props.paginationLimit),
  )

  watch(perPage, (value) => {
    localStorage.setItem('resourcePerPage', value.toString())
  })

  const goFirst = () => {
    emits('update:paginationPage', 1)
  }

  const goPrevious = () => {
    emits('update:paginationPage', props.paginationPage - 1)
  }

  const goNext = () => {
    emits('update:paginationPage', props.paginationPage + 1)
  }

  const goLast = () => {
    emits(
      'update:paginationPage',
      Math.ceil(count.value / props.paginationLimit),
    )
  }
</script>

<style scoped lang="scss"></style>
