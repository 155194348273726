<template>
  <div class="field">
    <label v-if="label" class="field__label">{{ label }}</label>
    <div class="field__wrapper form-control">
      <ai-icon
        v-if="prependIcon && !$slots.prependIcon"
        :icon="prependIcon"
        class="field__icon field__icon-prepend"
        @click="prependIconClick"
      />
      <slot name="prependIcon" />
      <input
        v-model="modelValue"
        class="field__input"
        :class="[size]"
        :type="type"
        :required="required"
        :placeholder="placeholder"
        :readonly="readonly"
        :disabled="disabled"
        :accept="accept"
        @change="change"
        @input="input"
      />
      <ai-icon
        v-if="appendIcon && !$slots.appendIcon"
        :icon="appendIcon"
        class="field__icon field__icon-append"
        @click="appendIconClick"
      />
      <slot name="appendIcon" />
    </div>
  </div>
</template>

<script setup lang="ts">
  import AiIcon from '@/components/app/global/AiIcon.vue'

  const modelValue = defineModel({ required: true })
  defineProps({
    label: {
      type: String,
      default: '',
    },
    type: {
      type: String,
      default: 'text',
    },
    placeholder: {
      type: String,
      default: '',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    size: {
      type: String,
      default: 'md',
    },
    required: {
      type: Boolean,
      default: false,
    },
    readonly: {
      type: Boolean,
      default: false,
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    accept: {
      type: String,
      default: '',
    },
  })

  const emits = defineEmits([
    'append-icon-click',
    'prepend-icon-click',
    'change',
    'input',
  ])

  const appendIconClick = () => {
    emits('append-icon-click')
  }

  const prependIconClick = () => {
    emits('prepend-icon-click')
  }

  const change = (e: Event) => {
    emits('change', e)
  }

  const input = (e: Event) => {
    emits('input', e)
  }
</script>

<style scoped lang="scss">
  .field {
    @apply flex flex-col;

    &__label {
      @apply text-gray-700 dark:text-gray-300 text-sm mb-1;
    }

    &__wrapper {
      @apply flex items-center;

      &:focus-within {
        @apply text-gray-900 dark:text-white;
      }
    }

    &__input {
      @apply flex-1 w-full bg-transparent border-none outline-none text-sm px-2 h-full;

      &[disabled],
      &:disabled {
        @apply bg-gray-200 text-gray-600 opacity-60 dark:opacity-80 cursor-not-allowed
            dark:bg-gray-700 dark:text-gray-400 dark:border-gray-600;
      }

      &:read-only {
        @apply bg-gradient-to-r from-gray-50 text-gray-600 dark:from-gray-800 dark:text-gray-400;
      }

      &.lg {
        @apply h-12 text-base;
      }
    }

    &__icon {
      @apply cursor-pointer;

      &-prepend {
        @apply mx-2;
      }

      &-append {
        @apply mx-2;
      }
    }
  }
</style>
