<template>
  <div class="training-container__answers answers flex flex-col w-full">
    <div
      v-if="showQuestion"
      class="answers__header bg-gray-100 dark:bg-gray-950/80 py-6 flex align-center"
    >
      <p class="answers__title flex-fill dark:text-white">
        {{ intent.question.text }}
      </p>
    </div>
    <div class="answers__wrapper pt-8 w-full flex">
      <img
        :src="imgLoader('logo-svg', 'svg')"
        alt="answers logo"
        class="answers__logo bg-white dark:bg-gray-950 p-2 -mt-1 w-9 h-9 rounded mr-4"
      />
      <div v-if="intent.answers.length > 0" class="answers__content w-full">
        <p class="answers__text dark:text-white">Choose the best answer...</p>
        <ai-radio
          class="answers__item my-2"
          v-for="(answer, index) in intent.answers"
          :key="answer.answerId || index"
          :value="answer"
          :id="answer.answerId.toString()"
          v-model="computedSelectedAnswer"
        >
          <p
            :class="[
              (
                answer && computedSelectedAnswer
                  ? answer.text !== computedSelectedAnswer.text ||
                    answer.answerId !== computedSelectedAnswer.answerId
                  : true
              )
                ? 'bg-gray-100 text-gray-500 dark:bg-gray-800 dark:text-gray-400'
                : 'bg-violet-100 text-violet-600 dark:bg-violet-700/70 dark:text-violet-100',
            ]"
            class="text-sm leading-6 px-4 py-2 rounded flex-1 transition-colors duration-200"
          >
            {{ answer.text }}
          </p>
          <div class="customize-answer flex items-center absolute">
            <ai-btn
              icon="place"
              size="lg"
              variant="clear"
              color="secondary"
              rounded
              @click="copyToTextarea(answer.text)"
            >
              Insert text to textarea
            </ai-btn>
          </div>
        </ai-radio>
        <ai-btn
          v-if="computedSelectedAnswer"
          prepend-icon="check-circle"
          color="accent"
          size="sm"
          @click="createIntent"
          >{{ $t('training.content.actions.save') }}</ai-btn
        >
      </div>
      <p v-else class="answers__text text-gray-400 text-center">
        {{ $t('training.content.no-answers') }}
      </p>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import type { PropType } from 'vue'
  import AiRadio from '@/components/ui/AiRadio.vue'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import imgLoader from '@/utils/img-loader'

  const props = defineProps({
    intent: {
      type: Object as PropType<any>,
      required: true,
    },
    selectedAnswer: {
      type: Object as PropType<any>,
      default: null,
    },
    showQuestion: {
      type: Boolean,
      default: true,
    },
  })

  const emits = defineEmits([
    'update:selected-answer',
    'create-intent',
    'get-answer-text',
    'copy',
  ])

  const computedSelectedAnswer = computed<any>({
    get: () => props.selectedAnswer,
    set: (answer: any) => {
      emits('update:selected-answer', answer)
      if (answer) {
        emits('get-answer-text', { text: answer.text })
      }
    },
  })

  const createIntent = () => {
    emits('create-intent')
  }

  const copyToTextarea = (answer: string) => {
    emits('copy', answer)
  }
</script>

<style scoped lang="scss">
  .answers {
    &__wrapper {
      @apply dark:bg-gray-900 pl-12 pr-14;
    }
    &__header {
      padding-right: 96px;
      padding-left: 96px;
    }
    &__logo {
      @apply w-8 h-8;
    }

    &__item {
      &:hover {
        .customize-answer {
          opacity: 1;
        }
      }
    }
  }

  .customize-answer {
    @apply -right-14 z-10 opacity-0 transition-all duration-300;
  }
</style>
