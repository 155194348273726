export default (state: any) => {
  const setResources = (payload: any) => {
    state.resources = payload
  }

  const setResource = (payload: any) => {
    state.resource = payload
  }

  return {
    setResources,
    setResource,
  }
}
