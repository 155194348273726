<template>
  <div class="training-chat__radio relative flex-1">
    <p
      v-element-size="setCopyPosition"
      :class="[
        (
          answer && selected
            ? answer.text !== selected.text ||
              answer.answerId !== selected.answerId
            : true
        )
          ? 'bg-gray-100 text-gray-500 dark:bg-gray-900 dark:text-gray-400'
          : 'bg-violet-100 text-violet-600 dark:bg-violet-700/70 dark:text-violet-100',
      ]"
      class="training-chat__answer text-sm leading-6 px-4 py-2 rounded flex-1 transition-colors duration-200"
    >
      {{ answer.text }}
    </p>
    <div class="customize-answer flex items-center absolute">
      <ai-btn
        icon="place"
        size="lg"
        variant="clear"
        color="secondary"
        rounded
        @click="copy"
      >
        Insert text to textarea
      </ai-btn>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { ref } from 'vue'
  import type { PropType } from 'vue'
  import { vElementSize } from '@vueuse/components'
  import { useChatStore } from '@/stores/chat'
  import AiBtn from '@/components/ui/AiBtn.vue'

  const chatStore = useChatStore()

  const props = defineProps({
    answer: {
      type: Object as PropType<any>,
      required: true,
    },
    selected: {
      type: Object as PropType<any>,
      default: null,
    },
  })

  const emits = defineEmits(['copy'])

  const copyPosition = ref(0)

  const copy = () => {
    emits('copy', props.answer.text)
    selectAnswer()
  }

  const setCopyPosition = ({ width }: { width: number }) => {
    copyPosition.value = width + 76
  }

  const selectAnswer = () => {
    chatStore.mutations.setSelectedAnswer(props.answer)
  }
</script>

<style scoped lang="scss">
  .training-chat {
    &__radio {
      &:hover {
        .customize-answer {
          opacity: 1;
        }
      }
    }
    &__copy {
      top: calc(50% - 10px);
      left: calc(100% + 0.25rem);
      z-index: 10;
      opacity: 0;
      transition: all 0.25s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .customize-answer {
    @apply top-0 -right-14 z-10 opacity-0 transition-all duration-300;
  }
</style>
