<template>
  <main class="main">
    <div class="container flex items-center justify-center">
      <div class="auth-card">
        <reset-card v-if="!sending" @sent="sent" />
        <reset-requested-card v-else />
      </div>
    </div>
  </main>
</template>

<script lang="ts" setup>
  import ResetCard from '@/components/app/auth/reset/ResetCard.vue'
  import ResetRequestedCard from '@/components/app/auth/reset/ResetRequestedCard.vue'
  import { ref } from 'vue'

  const sending = ref(false)

  const sent = () => {
    sending.value = true
  }
</script>

<style lang="scss" scoped>
  .auth-card {
    @apply w-full flex flex-col items-center justify-center rounded-lg p-12 max-w-lg;
  }
</style>
