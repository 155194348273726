export default (state: any) => {
  const setOrganizations = (payload: any) => {
    state.organizations = payload
  }

  const setOrganization = (payload: any) => {
    state.organization = payload
  }

  const setCurrent = (payload: any) => {
    state.current = payload
  }

  return {
    setOrganizations,
    setOrganization,
    setCurrent,
  }
}
