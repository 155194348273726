<template>
  <component
    class="btn"
    :is="to ? 'router-link' : 'button'"
    :class="[
      color + ' ' + variant + ' ' + size,
      rounded ? 'rounded-full' : 'rounded',
      { 'icon-only': icon },
    ]"
    :disabled="disabled"
    :to="to"
  >
    <ai-icon
      v-if="icon"
      :icon="icon"
      :size="iconSize"
      class="btn__icon btn__icon-main"
    />
    <ai-icon
      v-if="prependIcon && !icon"
      :icon="prependIcon"
      :size="iconSize"
      class="btn__icon btn__icon-prepend"
    />
    <span :class="[{ 'sr-only': icon }]">
      <slot />
    </span>
    <ai-icon
      v-if="appendIcon && !icon"
      :icon="appendIcon"
      :size="iconSize"
      class="btn__icon btn__icon-append"
    />
  </component>
</template>

<script setup lang="ts">
  import { computed } from 'vue'

  const props = defineProps({
    color: {
      type: String,
      default: 'primary',
    },
    variant: {
      type: String,
      default: 'default',
    },
    prependIcon: {
      type: String,
      default: '',
    },
    appendIcon: {
      type: String,
      default: '',
    },
    icon: {
      type: String,
      default: '',
    },
    disabled: {
      type: Boolean,
      default: false,
    },
    size: {
      type: String,
      default: '',
    },
    to: {
      type: [String, Object],
      default: '',
    },
    rounded: {
      type: Boolean,
      default: false,
    },
  })

  const iconSize = computed(() => {
    if (props.size === 'sm') {
      return 16
    } else if (props.size === 'xs') {
      return 12
    } else if (props.size === 'lg') {
      return 24
    } else {
      return 16
    }
  })
</script>

<style scoped lang="scss">
  .btn {
    /* @apply flex justify-center items-center px-4 py-2 h-10 text-sm rounded; */
    @apply cursor-pointer text-sm inline-flex items-center gap-2 justify-center h-10 px-4 border flex-shrink-0 border-transparent transition-colors transition-opacity duration-200 whitespace-nowrap focus-visible:outline-2 focus-visible:outline-woot-100;

    &.success {
      @apply bg-green-500 dark:bg-green-600 dark:hover:bg-green-700 text-white hover:bg-green-600;
    }

    &.primary {
      @apply bg-woot-500 text-white hover:bg-woot-600;
    }

    &.secondary {
      @apply bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-700;
    }

    &.accent {
      @apply bg-violet-500 text-white hover:bg-violet-600 dark:bg-violet-600 dark:hover:bg-violet-700;
    }

    &.warning {
      @apply bg-yellow-500 text-white hover:bg-yellow-600 dark:bg-yellow-600 dark:hover:bg-yellow-700;
    }

    &.alert {
      @apply bg-red-500 text-white hover:bg-red-600 focus-visible:outline-red-100;
    }

    &.smooth {
      &.primary {
        @apply bg-woot-50 dark:bg-woot-800 text-woot-700 dark:text-woot-50 hover:text-woot-700 dark:hover:text-white hover:bg-woot-100 dark:hover:bg-woot-700;
      }

      &.secondary {
        @apply bg-gray-100 dark:bg-gray-700 text-gray-700 dark:text-gray-100 hover:bg-gray-200 dark:hover:bg-gray-600;

        &.active {
          @apply bg-gray-300 dark:bg-gray-600;
        }
      }

      &.success {
        @apply bg-green-50 dark:bg-green-700 text-green-700 dark:text-green-100 hover:bg-green-100 dark:hover:bg-green-800 hover:text-green-800 dark:hover:text-green-100;
      }

      &.alert {
        @apply bg-red-50 dark:bg-red-700 dark:bg-opacity-50 text-red-700 dark:text-red-100 hover:bg-red-100 dark:hover:bg-red-600 dark:hover:bg-opacity-70;
      }

      &.warning {
        @apply bg-yellow-100 dark:bg-yellow-700 text-yellow-700 dark:text-yellow-100 hover:bg-yellow-200 dark:hover:bg-yellow-600;
      }
      &.accent {
        @apply bg-violet-100 dark:bg-violet-700 text-violet-700 dark:text-violet-100 hover:bg-violet-200 dark:hover:bg-violet-600;
      }
    }

    &.clear {
      @apply bg-transparent dark:bg-transparent;

      &.primary {
        @apply text-woot-500 dark:text-woot-400 hover:bg-woot-50 dark:hover:bg-woot-900/50 hover:text-woot-600 dark:hover:text-woot-200;
      }

      &.secondary {
        @apply text-gray-700 dark:text-gray-100 hover:bg-gray-100 dark:hover:bg-gray-700 hover:text-gray-800 dark:hover:text-gray-100 focus:bg-gray-50 dark:focus:bg-gray-700 active:bg-gray-100 dark:active:bg-gray-900;
      }

      &.success {
        @apply text-green-700 dark:text-green-100 hover:bg-green-50 dark:hover:bg-green-800 hover:text-green-800 dark:hover:text-green-100;
      }

      &.alert {
        @apply text-red-700 dark:text-red-100 hover:bg-red-50 dark:hover:bg-red-800 hover:text-red-700 dark:hover:text-red-100;
      }

      &.warning {
        @apply text-yellow-700  hover:bg-yellow-100 hover:text-yellow-800 dark:text-yellow-600 dark:hover:bg-yellow-900/50 dark:hover:text-yellow-500;
      }
    }

    &.text {
      @apply bg-transparent dark:bg-transparent hover:bg-transparent dark:hover:bg-transparent;

      &.primary {
        @apply text-woot-600 dark:text-woot-400 hover:text-woot-500 dark:hover:text-woot-200;
      }

      &.secondary {
        @apply text-gray-500 dark:text-gray-300  hover:text-gray-800 dark:hover:text-gray-100;
      }

      &.success {
        @apply text-green-700 dark:text-green-100;
      }

      &.alert {
        @apply text-red-700 dark:text-red-100  hover:text-red-700 dark:hover:text-red-100;
      }

      &.warning {
        @apply text-yellow-700  hover:text-yellow-800 dark:text-yellow-600;
      }
    }
    // Sizes
    &.xs {
      @apply h-6  px-1 text-xs;
    }

    &.sm {
      @apply h-8 text-sm px-2;
    }

    &.lg {
      @apply h-12 text-base;
    }

    &.icon-only {
      @apply px-0 w-10;

      &.xs {
        @apply w-6;
      }

      &.sm {
        @apply w-8;
      }

      &.lg {
        @apply w-12;
      }
    }

    &:disabled {
      @apply cursor-not-allowed opacity-50;
    }
  }
</style>
