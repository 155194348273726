import { showErrors } from '@/utils/errors'
import chat from '@/api/nests/chat'
import { useGlobalStore } from '@/stores/global'

export default (mutations: any) => {
  const globalStore = useGlobalStore()
  const askQuestion = async (question: any) => {
    try {
      globalStore.mutations.toggleLoader()
      const { data } = await chat.askQuestion(question)
      mutations.setQuestion(question.question)
      mutations.setAnswers(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    } finally {
      globalStore.mutations.toggleLoader()
    }
  }

  const startConversation = async (data: any) => {
    try {
      await chat.startConversation(data)
    } catch (error) {
      showErrors((error as any).response.data.message)
    }
  }

  return {
    askQuestion,
    startConversation,
  }
}
