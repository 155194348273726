<template>
  <div class="flex flex-col w-full">
    <div
      class="form bg-white dark:bg-gray-800 mb-4 rounded border dark:border-gray-700 w-full p-4"
    >
      <ai-input
        v-model="editedOptions.title"
        :label="$t('configuration.content.name')"
        class="w-full mb-4"
      />
      <div class="bg-gray-100 dark:bg-gray-900 rounded-md p-2">
        <ai-accordion
          v-model="showBehavior"
          :title="$t('configuration.content.behavior.title')"
          class="mb-2"
          @toggle="toggleBehavior"
        >
          <div class="flex flex-col w-full space-y-2 items-center">
            <ai-input
              v-for="(instruction, index) in editedOptions.options.instructions"
              v-model="editedOptions.options.instructions[index]"
              :key="index"
              append-icon="close"
              class="w-full"
              @append-icon-click="deleteInstruction(index)"
            />
            <ai-btn
              size="sm"
              color="secondary"
              prepend-icon="add-circle"
              variant="smooth"
              @click="addBehavior"
              >{{ $t('configuration.content.behavior.add') }}</ai-btn
            >
          </div>
        </ai-accordion>
        <ai-accordion
          v-model="showSettings"
          :title="$t('configuration.content.settings.title')"
          @toggle="toggleSettings"
        >
          <div class="flex w-full space-x-4 items-center">
            <ai-range-slider
              v-model="editedOptions.options.similarityThreshold"
              :min="0"
              :max="100"
              :label="$t('configuration.content.settings.similarity')"
              class="w-full"
            />
            <ai-range-slider
              v-model="editedOptions.options.temperature"
              :min="1"
              :max="10"
              :label="$t('configuration.content.settings.temperature')"
              class="w-full"
            />
          </div>
        </ai-accordion>
      </div>
      <ai-btn
        color="primary"
        class="mt-4 ml-auto"
        :disabled="!editedOptions.title"
        @click="saveNest"
        >{{ $t('configuration.content.save') }}</ai-btn
      >
    </div>
    <div
      class="form bg-white dark:bg-gray-800 mb-4 rounded border dark:border-gray-700 w-full p-4"
    >
      <h2>
        {{ $t('configuration.content.secret.title') }}
      </h2>
      <div class="flex items-end mt-2 input-group">
        <ai-input
          v-model="currentSecretKey"
          :label="$t('configuration.content.secret.secret')"
          class="w-full"
          :readonly="true"
        >
          <template #appendIcon>
            <ai-icon
              icon="copy"
              class="cursor-pointer mx-2"
              @click="clipboardCopy"
            />
          </template>
        </ai-input>
        <ai-btn
          icon="refresh"
          color="secondary"
          class="rounded-l-none input-group-append"
          @click="toggleRefreshKeyDialog"
        />
      </div>
    </div>
  </div>

  <ai-dialog
    size="sm"
    v-if="showRefreshDialog"
    :title="$t('revoke-token.title')"
    @close="toggleRefreshKeyDialog"
  >
    <div class="dialog-body">
      <p class="text-sm">{{ $t('revoke-token.description') }}</p>
      <div class="flex justify-end gap-2 mt-4">
        <ai-btn
          variant="clear"
          color="secondary"
          @click="toggleRefreshKeyDialog"
          >{{ $t('revoke-token.cancel') }}</ai-btn
        >
        <ai-btn color="warning" variant="smooth" @click="refreshSecretKey">{{
          $t('revoke-token.revoke')
        }}</ai-btn>
      </div>
    </div>
  </ai-dialog>
</template>

<script setup lang="ts">
  import { toast } from 'vue3-toastify'
  import { computed, reactive, ref, watch } from 'vue'
  import { useNestStore } from '@/stores/nests'
  import { useRoute } from 'vue-router'
  import { useI18n } from 'vue-i18n'
  import AiInput from '@/components/ui/AiInput.vue'
  import AiAccordion from '@/components/ui/AiAccordion.vue'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import AiDialog from '@/components/ui/AiDialog.vue'
  import AiRangeSlider from '@/components/ui/AiRangeSlider.vue'
  import AiIcon from '@/components/app/global/AiIcon.vue'

  const { t } = useI18n()
  const route = useRoute()
  const nestStore = useNestStore()

  const showRefreshDialog = ref(false)
  const showBehavior = ref(false)
  const showSettings = ref(false)
  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  nestStore.actions.fetchNest(orgId.value, nestId.value)
  nestStore.actions.fetchNestOptions(orgId.value, nestId.value)
  nestStore.actions.fetchSecret(orgId.value, nestId.value)

  const secret = computed(() => nestStore.getters.secret())
  const nest = computed(() => nestStore.getters.nest())
  const options = computed(() => nestStore.getters.nestOptions())

  const formatInstructions = (instructions: string[]) => {
    return instructions.map((str: string) => {
      let newStr = str
      if (newStr.startsWith('["')) {
        newStr = newStr.substring(2)
      }
      if (newStr.startsWith('"') || newStr.startsWith('[')) {
        newStr = newStr.substring(1)
      }
      if (newStr.endsWith('"]')) {
        newStr = newStr.substring(0, newStr.length - 2)
      }
      if (newStr.endsWith('"') || newStr.endsWith(']')) {
        newStr = newStr.substring(0, newStr.length - 1)
      }
      return newStr
    })
  }

  const currentSecretKey = computed(() => {
    if (!secret.value) {
      return ''
    }
    return secret.value.slice(0, -5).replace(/./g, '•') + secret.value.slice(-5)
  })

  const refreshSecretKey = async () => {
    await nestStore.actions.revokeSecret(orgId.value, nestId.value)
    await nestStore.actions.generateSecret(orgId.value, nestId.value)
    toggleRefreshKeyDialog()
  }

  const toggleBehavior = () => (showBehavior.value = !showBehavior.value)

  const toggleSettings = () => (showSettings.value = !showSettings.value)

  const toggleRefreshKeyDialog = () =>
    (showRefreshDialog.value = !showRefreshDialog.value)

  const clipboardCopy = () => {
    navigator.clipboard.writeText(secret.value)
    toast.success(t('configuration.copied'))
  }

  const editedOptions = reactive({
    title: '',
    options: {
      similarityThreshold: 0,
      temperature: 0,
      instructions: [''],
      conversationTimeout: 360001,
    },
  })

  watch([options], () => {
    editedOptions.title = nest.value.title
    editedOptions.options.similarityThreshold =
      options.value.similarityThreshold
    editedOptions.options.temperature = options.value.temperature
    editedOptions.options.instructions = formatInstructions(
      options.value.instructions,
    )
  })

  // const temperatureLengthTicks = {
  //   1: '1',
  //   10: '10',
  // }
  //
  // const similarityLengthTicks = {
  //   0: '0',
  //   100: '100',
  // }

  const addBehavior = () => editedOptions.options.instructions.push('')

  const saveNest = async () => {
    await nestStore.actions.updateNestOptions(
      orgId.value,
      nestId.value,
      editedOptions,
    )
    await nestStore.actions.fetchNests(orgId.value)
    await nestStore.actions.fetchNest(orgId.value, nestId.value)
  }

  const deleteInstruction = (index: string | number) =>
    editedOptions.options.instructions.splice(Number(index), 1)
</script>

<style scoped lang="scss"></style>
