<template>
  <main class="main">
    <div class="container flex flex-wrap content-start">
      <info-box
        :title="$t('history.info-box.title')"
        :description="$t('history.info-box.description')"
        img-name="customer-support"
        info-box-name="historyInfoBox"
        route-name="history"
        show-close-icon
      />
      <div
        class="bg-white dark:bg-gray-800 border dark:border-gray-700 rounded p-6 w-full"
      >
        <history-filter
          :page="paginationPage"
          :limit="paginationLimit"
          @update="update"
        />
        <history-table @open-interactions="openInteractions" />
        <history-pagination
          :pagination-page="paginationPage"
          :pagination-limit="paginationLimit"
          @update:pagination-page="setPage"
          @update:pagination-limit="setLimit"
        />
      </div>
    </div>
  </main>
  <history-interactions
    v-if="drawer"
    :current-dialog="currentDialog"
    @close="toggleDrawer"
  />
</template>

<script lang="ts" setup>
  import { computed, reactive, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { useNestStore } from '@/stores/nests'
  import { useDialogsStore } from '@/stores/dialogs'
  import HistoryFilter from '@/components/app/history/HistoryFilter.vue'
  import HistoryPagination from '@/components/app/history/HistoryPagination.vue'
  import HistoryTable from '@/components/app/history/HistoryTable.vue'
  import HistoryInteractions from '@/components/app/history/HistoryInteractions.vue'

  const route = useRoute()
  const nestStore = useNestStore()
  const dialogStore = useDialogsStore()

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const drawer = ref(false)
  const currentDialog = ref<any>(null)

  const paginationPage = ref(1)
  const paginationLimit = ref(
    parseInt(localStorage.getItem('historyPerPage') || '10'),
  )
  const filter = reactive({
    name: '',
    identifier: '',
    from: '',
    to: '',
  })

  nestStore.actions.fetchNest(orgId.value, nestId.value)
  nestStore.actions.fetchSecret(orgId.value, nestId.value)
  dialogStore.actions.fetchHistoryDialogs({
    orgId: orgId.value,
    nestId: nestId.value,
    page: 1,
    limit: localStorage.getItem('historyPerPage') || '10',
  })

  const update = (filterData: any) => {
    filter.name = filterData.name
    filter.identifier = filterData.identifier
    filter.from = filterData.date[0]
    filter.to = filterData.date[1]
  }

  const setPage = (page: number) => {
    paginationPage.value = page
    dialogStore.actions.fetchHistoryDialogs({
      orgId: orgId.value,
      nestId: nestId.value,
      page: page,
      limit: localStorage.getItem('historyPerPage') || '10',
      name: filter.name,
      identifier: filter.identifier,
      dateFrom: filter.from,
      dateTo: filter.to,
    })
  }

  const setLimit = (limit: number) => {
    paginationLimit.value = limit
    localStorage.setItem('historyPerPage', limit.toString())
    dialogStore.actions.fetchHistoryDialogs({
      orgId: orgId.value,
      nestId: nestId.value,
      page: paginationPage.value,
      limit: limit,
      name: filter.name,
      identifier: filter.identifier,
      dateFrom: filter.from,
      dateTo: filter.to,
    })
  }

  const openInteractions = (item: any) => {
    currentDialog.value = item
    toggleDrawer()
  }

  const toggleDrawer = () => {
    drawer.value = !drawer.value
  }
</script>

<style lang="scss" scoped>
  .expand-btn {
    position: absolute;
    top: 1rem;
    left: calc(0px - 48px);
    z-index: 1;
  }

  .history {
    &__logo {
      min-width: 32px;
      height: 32px;
    }
  }
</style>
