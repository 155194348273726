import axios from 'axios'
import { addParams } from '@/utils/route-helpers'

export default {
  getAll: () => {
    return axios.get('/nests')
  },
  get: (id: string) => {
    return axios.get('/nests/' + id)
  },
  getNestOptions: (id: string) => {
    return axios.get(`/nests/${id}/options`)
  },
  getResources: (id: string) => {
    return axios.get(`/nests/${id}/data-sources?page=1&limit=50`)
  },
  create: (data: any) => {
    return axios.post('/nests', data)
  },
  upload: (nestId: string, file: any) => {
    return axios.post(`/nests/${nestId}/files`, file, {
      headers: {
        'Content-Type': 'multipart/form-data',
      },
    })
  },
  createTextResource: (nestId: string, data: any) => {
    return axios.post(`/nests/${nestId}/files/text`, data)
  },
  getTextResource: (nestId: string, dataSourceId: string | number) => {
    return axios.get(`/nests/${nestId}/files/text/${dataSourceId}`)
  },
  updateTextResource: (nestId: string, dataSourceId: string, data: any) => {
    return axios.patch(`/nests/${nestId}/files/text/${dataSourceId}`, data)
  },
  updateTitleResource: (
    nestId: string,
    dataSourceId: string,
    title: string,
  ) => {
    return axios.patch(`/nests/${nestId}/files/${dataSourceId}`, { title })
  },
  webpage: (id: string, url: string) => {
    return axios.get(`/nests/${id}/data-sources/webpages`, {
      url,
    })
  },
  delete: (id: any) => {
    return axios.delete('/nests/' + id)
  },
  update: (id: string, data: any) => {
    return axios.post('/nests/' + id, data)
  },
  updateNest: (id: string, data: any) => {
    return axios.patch('/nests/' + id, data)
  },
  // Secret Key
  generateSecret: (id: string) => {
    return axios.post(`/nests/${id}/secret`)
  },
  getSecret: (id: string) => {
    return axios.get(`/nests/${id}/secret`)
  },
  revokeSecret: (id: string) => {
    return axios.delete(`/nests/${id}/secret`)
  },
  // Dialogs
  createDialog: (id: string, data: any) => {
    return axios.post(`/nests/${id}/dialogs`, data)
  },
  createIntent: (nestId: any, newIntent: any) => {
    return axios.post(`/nests/${nestId}/intents`, newIntent)
  },
  getIntents: (nestId: string) => {
    return axios.get(`/nests/${nestId}/intents`)
  },
  getIntent: (nestId: string, intentId: string) => {
    return axios.get(`/nests/${nestId}/intents/${intentId}`)
  },
  deleteIntent: (nestId: string, intentId: string) => {
    return axios.delete(`/nests/${nestId}/intents/${intentId}`)
  },
  updateIntent: (nestId: string, intentId: string, data: any) => {
    return axios.patch(`/nests/${nestId}/intents/${intentId}`, data)
  },
  getDataSourceFilePath: (nestId: string | string[], dataSourceId: string) => {
    return axios.get(`/nests/${nestId}/files/${dataSourceId}`)
  },
  deleteResource: (nestId: string, dataSourceId: any) => {
    return axios.delete(`/nests/${nestId}/data-sources/${dataSourceId}`)
  },
  getDialogs: (id: string) => {
    return axios.get(`/nests/${id}/dialogs`)
  },
  getHistoryDialogs: (
    id: string,
    page: string | number,
    limit: string | number,
    name: string,
    identifier: string,
    dateFrom: string,
    dateTo: string,
  ) =>
    axios.get(
      addParams(
        `/nests/${id}/dialogs?page=${page}&limit=${limit}`,
        {
          conversationStarter: name,
          guestIdentifier: identifier,
          dateFrom,
          dateTo,
        },
        '&',
      ),
    ),
  getInteractions: (nestId: string, dialogId: string, page: number) => {
    return axios.get(
      `/nests/${nestId}/dialogs/${dialogId}/interactions?limit=50&page=${page}`,
    )
  },
  getDialog: (nestId: string, dialogId: string) => {
    return axios.get(`/nests/${nestId}/dialogs/${dialogId}`)
  },
  getTrainingDialog: (nestId: string) => {
    return axios.get(`/nests/${nestId}/dialogs/training`)
  },
  askQuestion: (data: any) => {
    return axios.post('/chatbot/get-answers', data)
  },
}
