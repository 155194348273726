<template>
  <div class="training-chat__saved w-full">
    <div class="training-chat__header">
      <p class="mb-2 truncate w-full">{{ intent.question }}</p>
      <ai-btn
        color="alert"
        variant="smooth"
        prepend-icon="delete"
        @click="toggleDeleteDialog"
        >{{ $t('training.content.delete') }}</ai-btn
      >
    </div>
    <div class="training-chat__response bg-woot-50 dark:bg-woot-900">
      <p class="text-woot-500 text-sm mb-2">
        {{ $t('training.content.save') }}
      </p>
      <p class="mb-2">{{ intent.answer }}</p>
      <ai-btn
        color="primary"
        variant="smooth"
        prepend-icon="undo"
        @click="resetIntent"
        >{{ $t('training.content.reset') }}</ai-btn
      >
    </div>
  </div>
  <ai-dialog
    v-if="showDeleteDialog"
    :title="$t('nest.card.delete-dialog.title')"
    size="sm"
    @close="toggleDeleteDialog"
  >
    <div class="dialog-body text-sm">
      {{ $t('nest.card.delete-dialog.description-intent') }}
      <span>{{ intent.question }}</span>
    </div>
    <div class="flex flex-wrap items-center justify-end gap-1 dialog-body">
      <ai-btn variant="clear" color="secondary" @click="toggleDeleteDialog">{{
        $t('nest.card.delete-dialog.cancel')
      }}</ai-btn>
      <ai-btn color="alert" variant="smooth" @click="deleteIntent(false)">{{
        $t('nest.card.delete-dialog.confirm')
      }}</ai-btn>
    </div>
  </ai-dialog>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import type { PropType } from 'vue'
  import { useRoute } from 'vue-router'
  import { useIntentStore } from '@/stores/intents'
  import { useChatStore } from '@/stores/chat'
  import { useNestStore } from '@/stores/nests'
  import { useDialogsStore } from '@/stores/dialogs'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import AiDialog from '@/components/ui/AiDialog.vue'

  const route = useRoute()
  const nestStore = useNestStore()
  const chatStore = useChatStore()
  const dialogStore = useDialogsStore()
  const intentStore = useIntentStore()

  const props = defineProps({
    intent: {
      type: Object as PropType<any>,
      required: true,
    },
  })

  const emits = defineEmits(['delete-intent', 'reset-answer'])

  const showDeleteDialog = ref(false)

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const secret = computed(() => nestStore.getters.secret())
  const trainingDialog = computed(() => dialogStore.getters.trainingDialog())

  const toggleDeleteDialog = () =>
    (showDeleteDialog.value = !showDeleteDialog.value)

  const resetIntent = async () => {
    const savedIntent = {
      question: props.intent.question,
      answer: props.intent.answer,
    }

    intentStore.mutations.setResetedIntent(savedIntent)
    await deleteIntent(true)
    await chatStore.actions.askQuestion({
      question: savedIntent.question,
      secretKey: secret.value,
      // guestIdentifier: localStorage.getItem('email'),
      dialogId: trainingDialog.value.dialogId,
      training: true,
    })
    emits('reset-answer')
  }

  const deleteIntent = async (reset: boolean) => {
    await intentStore.actions.deleteIntent(
      orgId.value,
      nestId.value,
      props.intent.intentId,
    )
    await intentStore.actions.fetchIntents(orgId.value, nestId.value, false)
    if (!reset) {
      emits('delete-intent')
    }
  }
</script>

<style scoped lang="scss">
  .training-chat {
    &__header,
    &__response {
      padding: 16px 100px;
    }
  }
</style>
