<template>
  <div class="training-sidebar flex flex-col w-1/4 min-w-64">
    <h2
      class="uppercase tracking-widest text-gray-400 dark:text-gray-500 text-sm my-4 px-4"
    >
      {{ $t('training.content.stored') }}
    </h2>
    <div
      class="w-full flex-1 bg-white dark:bg-gray-800 border dark:border-gray-800 rounded overflow-y-auto p-2 flex flex-col gap-1 text-sm"
    >
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <skeleton-loader
          v-if="intentFlagLoading"
          :quantity="3"
          height="h-9"
          wrapper-class="p-2"
          space="2"
        />
      </transition>
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <div
          v-if="intents.length > 0 && !intentFlagLoading"
          class="training-sidebar__list w-full overflow-y-auto p-2 flex flex-col gap-1 text-sm"
        >
          <training-sidebar-item
            v-for="intent in intents"
            :key="intent.intentId"
            :intent="intent"
          />
        </div>
      </transition>
      <transition
        enter-active-class="animate__animated animate__faster animate__fadeIn"
        leave-active-class="animate__animated animate__faster animate__fadeOut"
      >
        <empty-state
          v-if="intents.length === 0 && !intentFlagLoading"
          class="training-sidebar__empty"
          img-name="EmptyInbox"
          title-size="lg"
          :title="$t('training.content.empty')"
        />
      </transition>
    </div>
  </div>
</template>

<script setup lang="ts">
  import { computed, onUnmounted } from 'vue'
  import { useIntentStore } from '@/stores/intents'
  import TrainingSidebarItem from '@/components/app/training/TrainingSidebarItem.vue'
  import EmptyState from '@/components/ui/EmptyState.vue'
  import SkeletonLoader from '@/components/ui/skeleton/SkeletonLoader.vue'

  const intentStore = useIntentStore()

  const intents = computed(() => intentStore.getters.intents())

  const intentFlagLoading = computed(() => intentStore.getters.flagLoading())

  onUnmounted(() => {
    intentStore.mutations.setFlags('loading', true)
  })
</script>

<style scoped lang="scss">
  .training-sidebar {
    &__list {
      animation-delay: 0.5s;
    }

    &__item {
      &.active {
        background-color: rgba(229, 232, 235, 1);
      }

      &:hover {
        background-color: rgba(229, 232, 235, 0.5);
      }
    }

    &__empty {
      animation-delay: 0.5s;
    }
  }

  .v-enter-active,
  .v-leave-active {
    transition: opacity 0.5s ease;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
  }
</style>
