<template>
  <div class="flex flex-1 w-full gap-4">
    <training-sidebar />
    <div class="flex flex-col w-full">
      <div
        class="bg-white dark:bg-gray-800 flex flex-col h-full rounded-md shadow-xl border dark:border-gray-700 overflow-hidden"
      >
        <training-chat @copy="copy" />
        <training-actions :copied="copiedText" @clear-copy="clearCopy" />
      </div>
    </div>
  </div>
</template>

<script setup lang="ts">
  import TrainingSidebar from '@/components/app/training/TrainingSidebar.vue'
  import TrainingChat from '@/components/app/training/TrainingChat.vue'
  import TrainingActions from '@/components/app/training/TrainingActions.vue'
  import { ref } from 'vue'

  const copiedText = ref('')

  const copy = (text: any) => {
    copiedText.value = text
  }

  const clearCopy = () => {
    copiedText.value = ''
  }
</script>

<style scoped lang="scss"></style>
