<template>
  <div class="w-full">
    <div
      :class="{ active: isSelected }"
      class="training-sidebar__item transition-colors duration-150 rounded flex items-center justify-space-between w-full gap-2 cursor-pointer"
    >
      <span
        class="training-sidebar__title truncate flex-1 block p-3"
        @click="selectedIntent"
      >
        {{ intent.question }}
      </span>
      <ai-btn
        v-if="isSelected"
        class="mr-2"
        variant="clear"
        icon="close"
        size="sm"
        color="primary"
        @click="toggleDeleteDialog"
      />
    </div>
  </div>
  <ai-dialog
    v-if="showDeleteDialog"
    :title="$t('nest.card.delete-dialog.title')"
    size="sm"
    @close="toggleDeleteDialog"
  >
    <div class="dialog-body text-sm">
      {{ $t('nest.card.delete-dialog.description-intent') }}
      <span>{{ intent.question }}</span>
    </div>
    <div class="flex flex-wrap items-center justify-end gap-1 dialog-body">
      <ai-btn variant="clear" color="secondary" @click="toggleDeleteDialog">{{
        $t('nest.card.delete-dialog.cancel')
      }}</ai-btn>
      <ai-btn color="alert" variant="smooth" @click="deleteIntent">{{
        $t('nest.card.delete-dialog.confirm')
      }}</ai-btn>
    </div>
  </ai-dialog>
</template>

<script setup lang="ts">
  import { computed, ref } from 'vue'
  import type { PropType } from 'vue'
  import { useRoute } from 'vue-router'
  import { useIntentStore } from '@/stores/intents'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import AiDialog from '@/components/ui/AiDialog.vue'

  const route = useRoute()
  const intentStore = useIntentStore()

  const props = defineProps({
    intent: {
      type: Object as PropType<any>,
      default: null,
    },
  })

  const showDeleteDialog = ref(false)

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const selected = computed(() => intentStore.getters.intent())
  const isSelected = computed(
    () => props.intent.intentId === selected.value?.intentId,
  )

  const toggleDeleteDialog = () =>
    (showDeleteDialog.value = !showDeleteDialog.value)

  const selectedIntent = () => {
    intentStore.actions.fetchIntent(
      orgId.value,
      nestId.value,
      props.intent.intentId,
    )
  }

  const deleteIntent = async () => {
    await intentStore.actions.deleteIntent(
      orgId.value,
      nestId.value,
      props.intent.intentId,
    )
    await intentStore.actions.fetchIntents(orgId.value, nestId.value, false)
  }
</script>

<style scoped lang="scss">
  .training-sidebar {
    &__item {
      &.active {
        @apply bg-woot-50 dark:bg-woot-800 text-woot-600 dark:text-woot-100 hover:text-woot-600 dark:hover:text-woot-100 dark:hover:bg-woot-800 hover:bg-woot-50;
      }

      &:hover {
        @apply bg-gray-100 dark:bg-gray-700;
      }
    }
  }
</style>
