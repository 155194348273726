import { reactive } from 'vue'

export default reactive<any>({
  organizations: {
    count: 0,
    items: [],
  },
  organization: null,
  current: null,
})
