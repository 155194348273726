<template>
  <main class="main">
    <div class="container flex flex-col">
      <info-box
        :title="$t('training.info-box.title')"
        :description="$t('training.info-box.description')"
        img-name="feedback-message"
        info-box-name="trainingInfoBox"
        route-name="training"
        show-close-icon
      />
      <training-container />
    </div>
  </main>
</template>

<script lang="ts" setup>
  import { computed } from 'vue'
  import { useRoute } from 'vue-router'
  import { useNestStore } from '@/stores/nests'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useIntentStore } from '@/stores/intents'
  import TrainingContainer from '@/components/app/training/TrainingContainer.vue'
  import { useGlobalStore } from '@/stores/global'

  const route = useRoute()
  const globalStore = useGlobalStore()
  const nestStore = useNestStore()
  const intentStore = useIntentStore()
  const dialogStore = useDialogsStore()

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const secret = computed(() => nestStore.getters.secret())
  const trainingDialog = computed(() => dialogStore.getters.trainingDialog())

  const getData = async () => {
    await globalStore.mutations.toggleLoader()
    await nestStore.actions.fetchNest(orgId.value, nestId.value)
    await nestStore.actions.fetchSecret(orgId.value, nestId.value)
    await dialogStore.actions.fetchTrainingDialog(orgId.value, nestId.value)
    await intentStore.actions.fetchIntents(orgId.value, nestId.value, true)
    if (!secret.value) {
      await nestStore.actions.generateSecret(orgId.value, nestId.value)
    }
    if (!trainingDialog.value) {
      await dialogStore.actions.createDialog(orgId.value, nestId.value, {
        guest: {
          identifier: localStorage.getItem('email'),
        },
        secretKey: secret.value,
        training: true,
      })
    }
    await globalStore.mutations.toggleLoader()
  }

  getData()
</script>

<style lang="scss" scoped></style>
