<template>
  <div class="overlay animate__animated animate__faster animate__fadeIn">
    <div class="empty-overlay" @click="close" />
    <div
      class="interactions animate__animated animate__faster animate__slideInRight"
      :style="'max-width: ' + sidebarWidth + 'px'"
    >
      <ai-btn
        class="interactions__close-icon"
        icon="close"
        color="secondary"
        variant="clear"
        @click="close"
      />
      <div class="flex items-start px-3 pt-12 pb-4 gap-4">
        <ai-btn
          :class="{ 'rotate-180': expand }"
          icon="expand"
          color="secondary"
          @click="toggleExpand"
          size="sm"
          variant="clear"
        />
        <history-interactions-header :current-dialog="props.currentDialog" />
      </div>

      <ul class="interactions__list">
        <history-interaction
          v-for="interaction in interactions"
          :key="interaction.interactionId"
          :interaction="interaction"
          @improve="improveAnswer"
        />
      </ul>
      <ai-btn
        v-if="page * 50 < interactionsCount"
        class="rounded-0"
        @click="loadMore"
        >{{ $t('history.content.interactions.load-more') }}</ai-btn
      >
    </div>
  </div>
</template>

<script lang="ts" setup>
  import type { PropType } from 'vue'
  import { useRoute } from 'vue-router'
  import { computed, ref } from 'vue'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useGlobalStore } from '@/stores/global'
  import HistoryInteractionsHeader from '@/components/app/history/HistoryInteractionsHeader.vue'
  import HistoryInteraction from '@/components/app/history/HistoryInteraction.vue'
  import AiBtn from '@/components/ui/AiBtn.vue'

  const route = useRoute()
  const globalStore = useGlobalStore()
  const dialogStore = useDialogsStore()

  const props = defineProps({
    currentDialog: {
      type: Object as PropType<any | null>,
      required: true,
    },
  })

  const emits = defineEmits(['update:show', 'close'])

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const expand = ref(false)
  const currentInteraction = ref(null)
  const page = ref<number>(1)

  props.currentDialog &&
    dialogStore.actions.fetchInteractions(
      orgId.value,
      nestId.value,
      props.currentDialog.dialogId.toString(),
      page.value,
    )

  const interactions = computed<any[]>(() => dialogStore.getters.interactions())
  const interactionsCount = computed<number>(() =>
    dialogStore.getters.interactionsCount(),
  )

  const close = () => {
    emits('close')
  }

  const sidebarWidth = computed(() => {
    return expand.value ? 900 : 400
  })

  const loadMore = () => {
    page.value++
    props.currentDialog &&
      dialogStore.actions.fetchInteractions(
        orgId.value,
        nestId.value,
        props.currentDialog.dialogId.toString(),
        page.value,
      )
  }

  const toggleExpand = () => {
    expand.value = !expand.value
  }

  const improveAnswer = (interaction: any) => {
    currentInteraction.value = interaction
    dialogStore.mutations.setInteraction(interaction)
    globalStore.mutations.toggleImproveHistoryAnswerDialog()
  }
</script>

<style lang="scss" scoped>
  .overlay {
    @apply z-30 fixed inset-0 w-full h-screen flex justify-center items-center;
  }

  .empty-overlay {
    @apply absolute inset-0 w-full h-full bg-black bg-opacity-30 dark:bg-opacity-50;
  }

  .interactions {
    @apply w-full flex flex-col h-screen bg-white dark:bg-gray-900 fixed right-0 top-0 z-50 shadow-2xl transition-all duration-500;

    &__close-icon {
      position: absolute;
      top: 0.5rem;
      right: 0.5rem;
      z-index: 1;
    }

    &__list {
      @apply flex-1 overflow-y-auto;
    }
  }

  .history {
    &__logo {
      min-width: 32px;
      height: 32px;
    }
  }
</style>
