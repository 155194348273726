export default (state: any) => {
  const organizations = () => {
    return state.organizations.items
  }

  const organization = () => {
    return state.organization
  }

  const current = () => {
    return state.current
  }

  return {
    organizations,
    organization,
    current,
  }
}
