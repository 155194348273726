<template>
  <table class="w-full">
    <thead>
      <tr class="text-gray-400 font-normal text-left text-sm">
        <th>
          {{ $t('history.content.table.starter') }}
        </th>
        <th>
          {{ $t('history.content.table.identifier') }}
        </th>
        <th>
          {{ $t('history.content.table.Interactions') }}
        </th>
        <th>
          {{ $t('history.content.table.last-update') }}
        </th>
      </tr>
    </thead>
    <tbody v-if="dialogs.length > 0" class="text-sm">
      <tr
        v-for="item in dialogs"
        :key="item.dialogId"
        class="border-t border-gray-100 dark:border-gray-700"
      >
        <td
          class="break-all pr-3 cursor-pointer"
          @click="openInteraction(item)"
        >
          {{ item.conversationStarter || '---' }}
        </td>
        <td class="text-gray-400 break-all pr-3">
          {{ item.guestIdentifier || '---' }}
        </td>
        <td>
          {{ item.totalInteractions || '---' }}
        </td>
        <td>
          <div class="date-time flex flex-col">
            <span class="date">{{
              moment(item.lastInteraction).format('DD.MM.YYYY') || '---'
            }}</span>
            <span class="time text-gray-400 text-xs flex items-center">
              <ai-icon icon="time" class="mr-1" :size="12" />{{
                moment(item.lastInteraction).format('HH:mm')
              }}</span
            >
          </div>
        </td>
      </tr>
    </tbody>
    <tbody v-else>
      <tr>
        <td colspan="4" class="text-center">
          <div class="text-gray-400 text-xl py-4">No dialogs yet</div>
        </td>
      </tr>
    </tbody>
  </table>
</template>

<script setup lang="ts">
  import { computed } from 'vue'
  import moment from 'moment'
  import { useDialogsStore } from '@/stores/dialogs'
  import AiIcon from '@/components/app/global/AiIcon.vue'

  const dialogStore = useDialogsStore()

  const emits = defineEmits(['open-interactions'])

  const dialogs = computed<any>(() => dialogStore.getters.historyDialogs())

  const openInteraction = (item: any) => {
    emits('open-interactions', item)
  }
</script>

<style scoped lang="scss">
  table {
    td {
      @apply py-4;
    }
  }
</style>
