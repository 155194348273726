export default (state: any) => {
  const setIntent = (payload: any) => {
    state.intent = payload
  }

  const setIntents = (payload: any) => {
    state.intents = payload
  }

  const setSavedIntent = (payload: any) => {
    state.stored = payload
  }

  const setResetedIntent = (payload: any) => {
    state.reseted = payload
  }

  const setFlags = (flagName: string, value: boolean) => {
    state.flags[flagName] = value
  }

  return {
    setIntent,
    setIntents,
    setSavedIntent,
    setResetedIntent,
    setFlags,
  }
}
