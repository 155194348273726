import { toast } from 'vue3-toastify'
import organizations from '@/api/nests/organizations'

export default (mutations: any) => {
  const fetchOrganizations = async () => {
    try {
      const { data } = await organizations.fetchOrganizations()
      mutations.setOrganizations(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const fetchOrganization = async (organizationId: string) => {
    try {
      const { data } = await organizations.fetchOrganization(organizationId)
      mutations.setOrganization(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const createOrganization = async (data: any) => {
    try {
      await organizations.createOrganization(data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const deleteOrganization = async (organizationId: string) => {
    try {
      await organizations.deleteOrganization(organizationId)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }
  const updateOrganization = async (organizationId: string, data: any) => {
    try {
      await organizations.updateOrganization(organizationId, data)
    } catch (error) {
      toast.error((error as any).response.data.message)
    }
  }

  return {
    fetchOrganizations,
    fetchOrganization,
    createOrganization,
    deleteOrganization,
    updateOrganization,
  }
}
