<template>
  <div class="training-actions flex items-center px-10 mt-auto gap-4 pt-3 pb-6">
    <button
      v-if="question && !intent"
      class="expand-btn bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-700"
      :class="{ 'bg-opacity-50 cursor-pointer': flagLoading }"
      @click="clear"
    >
      <ai-icon icon="undo" class="flex-shrink-0" />
      <span class="text-sm whitespace-nowrap">{{
        $t('training.content.actions.reset')
      }}</span>
    </button>
    <button
      v-if="intent"
      class="expand-btn bg-gray-200 dark:bg-gray-600 text-gray-700 dark:text-gray-100 hover:bg-gray-300 dark:hover:bg-gray-700"
      @click="clear"
    >
      <ai-icon icon="new-message" class="flex-shrink-0" />
      <span class="text-sm whitespace-nowrap">{{
        $t('training.content.actions.create')
      }}</span>
    </button>
    <form
      v-if="!question"
      class="form rounded flex flex-1 items-center"
      @submit.prevent="askQuestion"
    >
      <ai-textarea
        v-model="ownQuestion"
        :disabled="!!intent || !fetched"
        class="shadow-none flex-1"
        :placeholder="
          !!intent || !fetched
            ? $t('training.content.actions.locked')
            : $t('training.content.actions.type-away')
        "
        @keydown.enter.exact.prevent="askQuestion"
      >
        <template #appendIcon>
          <ai-btn
            class="m-2"
            icon="send"
            type="submit"
            rounded
            size="sm"
            :disabled="disabledQuestion || !fetched"
          />
        </template>
      </ai-textarea>
    </form>
    <form
      v-else
      class="form rounded flex flex-1 items-center"
      @submit.prevent="addAnswer"
    >
      <ai-textarea
        v-model="ownAnswer"
        :disabled="!!intent"
        class="shadow-none flex-1"
        :placeholder="
          !!intent
            ? $t('training.content.actions.reset-or-delete')
            : $t('training.content.actions.own')
        "
        @keydown.enter.exact.prevent="addAnswer"
      >
        <template #appendIcon>
          <ai-btn
            class="m-2"
            icon="send-new"
            type="submit"
            rounded
            size="sm"
            :disabled="disabledAnswer"
          />
        </template>
      </ai-textarea>
    </form>
  </div>
</template>

<script setup lang="ts">
  import { computed, ref, watch } from 'vue'
  // import { useRoute } from 'vue-router'
  import { useChatStore } from '@/stores/chat'
  import { useDialogsStore } from '@/stores/dialogs'
  import { useNestStore } from '@/stores/nests'
  import { useIntentStore } from '@/stores/intents'
  import AiIcon from '@/components/app/global/AiIcon.vue'
  import AiTextarea from '@/components/ui/AiTextarea.vue'
  import AiBtn from '@/components/ui/AiBtn.vue'

  // const route = useRoute()
  const chatStore = useChatStore()
  const nestStore = useNestStore()
  const intentStore = useIntentStore()
  const dialogStore = useDialogsStore()

  const props = defineProps({
    copied: {
      type: String,
      required: true,
    },
    disabledBtn: {
      type: Boolean,
      default: false,
    },
  })

  const emits = defineEmits(['clear-copy'])

  // const orgId = computed(() => route.params.orgId as string)
  // const nestId = computed(() => route.params.nestId as string)

  const ownQuestion = ref('')
  const ownAnswer = ref('')
  const fetched = ref(true)

  const disabledQuestion = computed(() => {
    return ownQuestion.value.length === 0
  })
  const disabledAnswer = computed(() => {
    return ownAnswer.value.length === 0
  })
  const secret = computed(() => nestStore.getters.secret())
  const trainingDialog = computed(() => dialogStore.getters.trainingDialog())
  const question = computed(() => chatStore.getters.question())
  const intent = computed(() => intentStore.getters.intent())
  const flagLoading = computed(() => intentStore.getters.flagLoading())

  const askQuestion = async () => {
    fetched.value = false
    if (disabledQuestion.value) return
    await chatStore.actions.askQuestion({
      question: ownQuestion.value,
      secretKey: secret.value,
      // guestIdentifier: localStorage.getItem('email'),
      dialogId: trainingDialog.value.dialogId,
      training: true,
    })
    ownQuestion.value = ''
    fetched.value = true
  }

  const addAnswer = () => {
    if (disabledAnswer.value) return
    const newAnswer = {
      text: ownAnswer.value,
      answerId: (Math.floor(Math.random() * 100) + 1).toString(),
      reaction: 'no-reaction',
    }
    console.info(chatStore.getters.answers())
    chatStore.mutations.addAnswer(newAnswer)
    ownAnswer.value = ''
    chatStore.mutations.setSelectedAnswer(newAnswer)
  }

  const clear = () => {
    if (!flagLoading.value) {
      ownQuestion.value = ''
      ownAnswer.value = ''
      fetched.value = true
      intentStore.mutations.setResetedIntent(null)
      intentStore.mutations.setIntent(null)
      chatStore.mutations.setQuestion('')
      chatStore.mutations.setAnswers([])
      chatStore.mutations.setSelectedAnswer(null)
    }
  }

  watch(
    () => props.copied,
    () => {
      if (props.copied) {
        ownAnswer.value = props.copied
        emits('clear-copy')
      }
    },
  )
</script>

<style scoped lang="scss">
  .expand-btn {
    @apply h-10 rounded-full flex flex-row flex-nowrap items-center gap-3 px-3 overflow-hidden max-w-10 hover:max-w-full transition-all duration-500 ease-in-out;
  }
</style>
