import { reactive } from 'vue'

// const state = {
//   createNestedDialog: false,
//   deleteNestedDialog: false,
//   uploadResourceDialog: false,
//   editorDialog: false,
//   interactionsDialog: false,
//   improveInteractionDialog: false,
//   improveTestingDialog: false,
//   loader: false,
//   createUserDialog: false,
//   adminDetailsSidebar: false,
//   inviteMemberDialog: false,
//   createOrganizationDialog: false,
//   editMemberDialog: false,
//   editOrganizationDialog: false,
// }

export default reactive<any>({
  nestDialog: false,
  deleteNestDialog: false,
  resourceDialog: false,
  resourceEditorDialog: false,
  improveAnswerDialog: false,
  improveHistoryAnswerDialog: false,
  loader: false,
  createUserDialog: false,
  adminDetailsSidebar: false,
  inviteMemberDialog: false,
  createOrganizationDialog: false,
  editMemberDialog: false,
  editOrganizationDialog: false,
})
