<template>
  <div class="flex w-full flex-col dark:bg-gray-900 pb-4">
    <history-chat-content
      v-model:selected-answer="selectedAnswer"
      :intent="newIntent"
      @create-intent="createIntent"
      @get-answer-text="setAnswerText"
      @copy="copy"
    />
    <form class="mt-8 px-12" @submit.prevent="addAnswer">
      <ai-textarea
        v-model="answerField"
        class="shadow-none flex-1"
        :placeholder="$t('improve-dialog.add')"
        @keydown.enter.exact.prevent="addAnswer"
      >
        <template #appendIcon>
          <ai-btn
            class="m-2"
            icon="send-new"
            type="submit"
            size="sm"
            rounded
            :disabled="!answerField"
          />
        </template>
      </ai-textarea>
      <!--      <ai-input-->
      <!--        v-model="answerField"-->
      <!--        color="primary"-->
      <!--        class="shadow-none flex-1"-->
      <!--        density="compact"-->
      <!--        :placeholder="$t('improve-dialog.add')"-->
      <!--        @keydown.enter.exact.prevent="addAnswer"-->
      <!--      />-->
      <!--      <ai-btn-->
      <!--        icon="editor"-->
      <!--        color="primary"-->
      <!--        type="submit"-->
      <!--        size="sm"-->
      <!--        class="text-medium-emphasis mr-2"-->
      <!--        :disabled="!answerField"-->
      <!--      />-->
    </form>
  </div>
</template>

<script setup lang="ts">
  import { computed, type PropType, reactive, ref } from 'vue'
  import { useRoute } from 'vue-router'
  import { useIntentStore } from '@/stores/intents'
  import AiBtn from '@/components/ui/AiBtn.vue'
  import AiTextarea from '@/components/ui/AiTextarea.vue'
  import HistoryChatContent from '@/components/app/history/HistoryChatContent.vue'

  const route = useRoute()
  const intentStore = useIntentStore()

  const props = defineProps({
    intent: {
      type: Object as PropType<any>,
      required: true,
    },
  })

  const emits = defineEmits(['close'])

  const orgId = computed(() => route.params.orgId as string)
  const nestId = computed(() => route.params.nestId as string)

  const textAnswer = ref('')
  const selectedAnswer = ref<any>(null)
  const answerField = ref('')
  const newIntent = reactive({
    answers: props.intent.answers,
    question: {
      text: props.intent.question,
    },
  })

  const setAnswerText = (value: { text: string }) => {
    textAnswer.value = value.text
  }

  const addAnswer = () => {
    if (answerField.value) {
      newIntent.answers.push({
        text: answerField.value,
        answerId: (Math.floor(Math.random() * 100) + 1).toString(),
        reaction: null,
      })
      textAnswer.value = answerField.value
      selectedAnswer.value = {
        text: answerField.value,
        answerId: (Math.floor(Math.random() * 100) + 1).toString(),
        reaction: null,
      }
      answerField.value = ''
    }
  }

  const copy = (answerText: string) => {
    answerField.value = answerText
  }

  const createIntent = async () => {
    const intent = {
      question: newIntent.question.text,
      answer: textAnswer.value,
    }
    await intentStore.actions.createIntent(orgId.value, nestId.value, intent)
    emits('close')
  }
</script>

<style scoped lang="scss"></style>
