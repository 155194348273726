<template>
  <transition>
    <div
      v-if="show"
      class="info-box flex items-center bg-woot-50 dark:bg-woot-700/70 text-woot-800 dark:text-woot-100 relative rounded-lg mb-8 w-full"
    >
      <div class="d-flex w-100 justify-space-between align-center px-8 py-5">
        <div class="d-flex flex-column flex-fill pr-8">
          <h2 class="text-2xl mb-2">{{ title }}</h2>
          <p
            class="text-woot-800 dark:text-woot-100 text-sm w-full max-w-[70%] lg:max-w-xl"
          >
            {{ description }}
          </p>
          <slot />
        </div>
        <img
          class="info-box__img"
          v-if="imgName"
          :src="imgPath(routeName)"
          alt="parrot-img"
        />
        <ai-icon
          v-if="showCloseIcon"
          size="16"
          icon="close"
          class="info-box__icon absolute cursor-pointer"
          @click="close"
        />
      </div>
    </div>
  </transition>
</template>

<script setup lang="ts">
  import { ref } from 'vue'

  const props = defineProps({
    title: {
      type: String,
      default: '',
    },
    description: {
      type: String,
      default: '',
    },
    infoBoxName: {
      type: String,
      default: '',
    },
    imgName: {
      type: String,
      default: '',
    },
    showCloseIcon: {
      type: Boolean,
      default: false,
    },
    routeName: {
      type: String,
      default: 'resources',
    },
  })

  interface ParrotImagesInterface {
    [key: string]: string[]
  }

  const emits = defineEmits(['close'])

  const show = ref(!localStorage.getItem(props.infoBoxName))

  const parrotImages: ParrotImagesInterface = {
    config: ['config-1.png'],
    resources: ['resources-1.png', 'resources-2.png', 'resources-3.png'],
    testing: ['testing-1.png', 'testing-2.png', 'testing-3.png'],
    training: ['training-1.png', 'training-2.png'],
    history: ['history-1.png', 'history-2.png'],
    profile: ['profile-1.png', 'profile-2.png'],
  }

  const imgPath = (routeName: string): any => {
    const randomParrotImg = Number(
      Math.floor(Math.random() * parrotImages[routeName].length),
    )
    return new URL(
      `../../../assets/parrots/${parrotImages[routeName][randomParrotImg]}`,
      import.meta.url,
    ).href
  }

  const close = () => {
    emits('close')
    localStorage.setItem(props.infoBoxName, 'false')
    show.value = false
  }
</script>

<style scoped lang="scss">
  .info-box {
    min-height: 150px;

    &__img {
      position: absolute;
      right: 3rem;
      top: -12.5px;
      aspect-ratio: 1 / 1;
      width: 175px;
    }

    &__icon {
      opacity: 0.5;
      top: 0.75rem;
      right: 0.75rem;
      transition: all 0.25s ease-in-out;

      &:hover {
        opacity: 1;
      }
    }
  }

  .v-enter-active,
  .v-leave-active {
    max-height: 500px;
    transition: all 0.5s ease-out;
  }

  .v-enter-from,
  .v-leave-to {
    opacity: 0;
    max-height: 0;
  }
</style>
